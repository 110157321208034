export function allConsentGranted() {
  const { gtag } = useGtag()

  gtag('consent', 'update', {
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    ad_storage: 'granted',
    analytics_storage: 'granted'
  })
}
export function allConsentDenied() {
  const { gtag } = useGtag()

  gtag('consent', 'update', {
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    ad_storage: 'denied',
    analytics_storage: 'denied',
  })
}

export function useGtagCookieConsent() {
  const { cookiesEnabledIds } = useCookieControl()

  watch(
    () => cookiesEnabledIds.value,
    (current, previous) => {
      if (!previous?.includes('marketing') && current?.includes('marketing')) {
        allConsentGranted();
      } else if (previous?.includes('marketing') && !current?.includes('marketing')) {
        allConsentDenied();
      }
    },
    { deep: true, immediate: true },
  )
}

